import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { Box, Flex } from 'rebass'
import { MdArrowForward } from 'react-icons/md'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'
import { kebabCase } from 'lodash'
import styled from 'styled-components'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'

const AccordionWrapper = styled(Accordion)`
  h3 {
    border-top: 1px solid ${props => props.theme.colors.gray[100]};
    cursor: pointer;
    margin-bottom: 0;
    padding: 0.25em 0;
    svg {
      fill: ${props => props.theme.colors.gray[400]};
    }
    &:hover {
      color: ${props => props.theme.colors.primary[500]};
      svg {
        fill: ${props => props.theme.colors.primary[500]};
      }
    }
  }

  h4 {
    color: ${props => props.theme.colors.gray[600]};
  }
`

const ServicesHero = styled(Section)`
  background-color: ${props => props.theme.colors.primary[500]};
  color: ${props => props.theme.colors.white};
  padding: 10em 0 6em 0;
`

const Services = ({ data }) => {
  const page = data.prismicServicespage.data
  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />

      <ServicesHero>
        <Container>
          <Flex flexWrap="wrap" mx={-8}>
            <Box width={[1, 1, 1 / 2]} px={8}>
              <h1>{page.hero_text.text}</h1>
            </Box>
            <Box width={[1, 1, 1 / 2]} px={8}>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.content.html,
                }}
              />
            </Box>
          </Flex>
        </Container>
      </ServicesHero>

      <Box width={1}>
        <BackgroundImage Tag="div" fluid={page.hero_image.fluid}>
          <Box
            css={`
              padding: 18em 0;
            `}
          />
        </BackgroundImage>
      </Box>

      <Section>
        <Container>
          <Flex>
            <Box width={1}>
              <h2>{page.title.text}</h2>
              <AccordionWrapper allowMultipleExpanded allowZeroExpanded>
                {data.allPrismicService.edges.map(({ node: service }) => (
                  <AccordionItem key={`${kebabCase(service.data.title.text)}`}>
                    <AccordionItemHeading
                      id={`${kebabCase(service.data.title.text)}`}
                    >
                      <AccordionItemButton>
                        <h3>
                          {service.data.title.text}{' '}
                          <MdArrowForward
                            css={`
                              font-size: 1em;
                              position: relative;
                              top: 5px;
                            `}
                          />
                        </h3>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Flex flexWrap="wrap" mx={-8} my={10}>
                        <Box width={[1, 2 / 3, 1 / 2]} px={8}>
                          <h4><strong>{service.data.subheading.text}</strong></h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: service.data.content.html,
                            }}
                          />
                        </Box>
                        <Box width={[1, 1 / 3, 1 / 2]} px={8} mt={[8, 0]}>
                          <Img
                            fluid={service.data.service_image.fluid}
                            alt={service.data.subheading.text}
                          />
                        </Box>
                      </Flex>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </AccordionWrapper>
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Services

export const query = graphql`
  query ServicesQuery {
    prismicServicespage {
      data {
        content {
          html
        }
        title {
          text
        }
        hero_text {
          html
          text
        }
        hero_image {
          alt
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        meta_description
        meta_title
      }
      uid
      id
    }
    allPrismicService(sort: { fields: data___position, order: ASC }) {
      edges {
        node {
          data {
            position
            title {
              text
            }
            subheading {
              text
            }
            service_image {
              fluid(maxWidth: 1500) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            content {
              html
            }
          }
        }
      }
    }
  }
`
